.numbers-section {
  padding: 20px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%; /* Span full width of the page */
  box-sizing: border-box; /* Ensure padding is included in the width */
}

/* no left and right padding */
.numbers-section .content {
  padding-left: 0;
  padding-right: 0;
}

.number {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center the items horizontally */
  margin: 0; /* Space around each number block */
  font-size: 1.2em; /* Adjust font size as needed */
  flex: 1 1 calc(25% - 40px); /* Ensure blocks are flexible and responsive */
  box-sizing: border-box;
  text-align: center; /* Center the text inside each block */
}

@media (max-width: 768px) {
  .number {
    width: 100%; /* Make each number block take full width on smaller screens */
  }
}

.highlight {
  font-size: 1.7em;
  font-weight: bold;
  margin-bottom: 5px; /* Add some space below the highlighted text */
}
