.logo {
  margin-right: auto; /* Pushes the logo to the left */
  cursor: pointer;
}

.logo img {
  height: 40px;
}

.header-links {
  margin-left: auto; /* Pushes the links to the right */
  display: flex;
}

.header-links a {
  margin-left: 20px;
  color: white;
  text-decoration: none;
}

.header-links a:hover {
  text-decoration: underline;
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
  .header-links a {
    margin-left: 15px;
  }
}

@media (max-width: 768px) {
  .header-links {
    flex-direction: column;
    align-items: flex-end;
  }

  .header-links a {
    margin-left: 0;
    margin-bottom: 10px;
  }

}

@media (max-width: 480px) {
  .header-links {
    flex-direction: column;
    align-items: center;
    margin-left: 0;
    margin-top: 10px;
  }

  .header-links a {
    margin-left: 0;
    margin-bottom: 10px;
    font-size: 14px;
  }

  .logo {
    margin: auto;
  }
}