  .markdown {
    line-height: 1.6;
    display: block;
    text-align: left;
    padding: 20px 40px;
  }

  .markdown h1 {
    font-size: 2em;
    margin-bottom: 0.5em;
  }

  .markdown h2 {
    font-size: 1.75em;
    margin-bottom: 0.5em;
  }

  .markdown h3 {
    font-size: 1.5em;
    margin-bottom: 0.5em;
  }

  .markdown p {
    margin-bottom: 1em;
  }

  .markdown a {
    text-decoration: none;
  }

  .markdown a:hover {
    text-decoration: underline;
  }

  .markdown ul,
  .markdown ol {
    margin-top: 1em;
    margin-left: 1.5em;
    padding-left: 1.5em;
    margin-bottom: 1em;
  }

  li {
    margin-bottom: 0.5em;
  }

  .markdown blockquote {
    padding: 0.5em 1em;
    margin: 0;
    border-left: 0.25em solid #dfe2e5;
    background-color: #f8f8f8;
  }

  .markdown code {
    font-family: 'Courier New', Courier, monospace;
    background-color: #f8f8f8;
    padding: 0.2em 0.4em;
    border-radius: 3px;
  }

  /* Media Queries for Responsiveness */
@media (max-width: 1024px) {
  .markdown {
    padding: 20px 30px;
  }

  .markdown h1 {
    font-size: 1.75em;
  }

  .markdown h2 {
    font-size: 1.5em;
  }

  .markdown h3 {
    font-size: 1.25em;
  }
}

@media (max-width: 768px) {
  .markdown {
    padding: 20px 20px;
  }

  .markdown h1 {
    font-size: 1.5em;
  }

  .markdown h2 {
    font-size: 1.25em;
  }

  .markdown h3 {
    font-size: 1.1em;
  }
}

@media (max-width: 480px) {
  .markdown {
    padding: 20px 10px;
  }

  .markdown h1 {
    font-size: 1.25em;
  }

  .markdown h2 {
    font-size: 1.1em;
  }

  .markdown h3 {
    font-size: 1em;
  }

  .markdown ul,
  .markdown ol {
    margin-left: 1em;
    padding-left: 1em;
  }
}