.demo-request {
  max-width: 640px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.demo-title {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.demo-description {
  font-size: 14px;
  margin-bottom: 20px;
  text-align: center;
}

.demo-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.submit-button {
  padding: 10px 20px;
  font-size: 18px;
  color: white;

  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  align-self: center;
}

.submit-button:hover {
  background-color: darkblue;
}
