.learn-more .top-message {
  width: 100%;
}

.learn-more .right-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 30px;
  align-self: flex-start;
}

.learn-more .left-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
