.content-section {
  display: flex;
  padding: 20px 80px 40px;
  color: black;
}

.left-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 20px;
  align-self: flex-start;
}

.right-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.large-image {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.buttons {
  display: flex;
  gap: 10px;
}

.button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.link-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1.125em;
  font-weight: bold;
  background-color: #ffc425;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
  .content-section {
    flex-direction: column;
    padding: 20px 40px 40px;
  }

  .left-section {
    padding-right: 0;
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .content-section {
    padding: 20px 20px 40px;
  }

  .button, .link-button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .large-image {
    width: 90%;
  }
}

@media (max-width: 480px) {
  .content-section {
    padding: 20px 10px 40px;
  }

  .button, .link-button {
    font-size: 12px;
    padding: 6px 12px;
  }

  .large-image {
    width: 100%;
  }
}
