/* Testimonials Section */
.testimonials-section {
  background-color: white;
  padding: 40px 20px;
  text-align: center;
}

.testimonials-title {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
}

.quote-icon {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}

.testimonial {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.testimonial-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.arrow {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.testimonial-quote {
  font-size: 20px;
  max-width: 600px;
  margin-bottom: 20px;
}
.customer-info {
  display: flex;
  align-items: center;
}
.customer-info.no-image {
  justify-content: center;
}
.profile-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.customer-details {
  text-align: left;
}
.customer-name {
  font-weight: bold;
  margin-bottom: 5px;
}

.customer-title {
  font-size: 14px;
  color: #666;
  margin-bottom: 5px;
}

.customer-company {
  font-size: 14px;
  color: #666;
}

/* Dots */
.dots {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dot:hover {
  background-color: #333;
  cursor: pointer;
}

.dot.active {
  background-color: #333;
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
  .testimonials-title {
    font-size: 30px;
  }

  .testimonial-quote {
    font-size: 18px;
    max-width: 90%;
  }

  .profile-image {
    width: 45px;
    height: 45px;
  }

  .customer-title,
  .customer-company {
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  .testimonials-title {
    font-size: 24px;
  }

  .quote-icon {
    width: 80px;
    height: 80px;
  }

  .testimonial {
    flex-direction: column;
  }

  .testimonial-quote {
    font-size: 16px;
    max-width: 90%;
  }

  .profile-image {
    width: 40px;
    height: 40px;
  }

  .customer-title,
  .customer-company {
    font-size: 12px;
  }

  .arrow {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .testimonials-section {
    padding: 20px 10px;
  }

  .testimonials-title {
    font-size: 20px;
  }

  .quote-icon {
    width: 60px;
    height: 60px;
  }

  .testimonial-quote {
    font-size: 14px;
    max-width: 100%;
  }

  .profile-image {
    width: 35px;
    height: 35px;
  }

  .customer-title,
  .customer-company {
    font-size: 11px;
  }

  .arrow {
    font-size: 18px;
  }
}