.typing-container {
    color:  #0058a0;
    font-size: 2em;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
}

.cursor {
    display: inline-block;
    background-color:  #0058a0;
    height: 1em;
    width: 2px;
    margin-left: 2px;
    animation: blink 0.7s steps(1) infinite;
}

@keyframes blink {
    50% {
        background-color: transparent;
    }
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
    .typing-container {
        font-size: 1.75em;
    }
}

@media (max-width: 768px) {
    .typing-container {
        font-size: 1.5em;
    }
}

@media (max-width: 480px) {
    .typing-container {
        font-size: 1.25em;
    }
}