.more-features-section {
  color: white;
  padding: 40px 80px;
  text-align: center;
}

.more-features-title {
  font-size: 36px;
  font-weight: bold;
  margin: 0 auto 20px;
}

.more-features-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.feature {
  display: flex;
  align-items: flex-start;
  width: calc(50% - 20px); /* Two features per row with spacing */
  margin-bottom: 20px;
}

.feature-icon {
  margin: 5px 30px 10px;
}

.feature-content {
  text-align: left;
}

.feature-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 2px;
}

.feature-text {
  font-size: 12px;
}

.view-all {
  margin: 20px auto 0;
  cursor: pointer;
  font-size: 18px;
  color: #bbf6fc;
  text-decoration: underline;
}

@media (max-width: 1024px) {
  .more-features-section {
    padding: 40px 40px;
  }

  .feature {
    width: calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  .more-features-section {
    padding: 40px 20px;
  }

  .more-features-title {
    font-size: 30px;
  }

  .feature {
    width: calc(100% - 20px);
  }

  .feature-title {
    font-size: 13px;
  }

  .feature-icon {
    margin: 5px 20px 10px;
  }

  .feature-text {
    font-size: 11px;
  }

  .view-all {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .more-features-section {
    padding: 20px 10px;
  }

  .more-features-title {
    font-size: 24px;
  }

  .feature {
    width: calc(100% - 20px);
    margin-bottom: 20px;
  }
  .feature-icon {
    margin: 5px 10px 10px;
  }
  .feature-title {
    font-size: 12px;
  }

  .feature-text {
    font-size: 10px;
  }

  .view-all {
    font-size: 14px;
  }
}