
.features-section {
  padding: 0 2em;
}

.content .features-title {
  font-size: 2em;
  font-weight: 900;
  margin: 1.125em auto;
}

.features-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.125em;
}

.feature-block {
  background-color: #f9f9f9;
  padding: 1.125em;
  border-radius: 0.5em;
  box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1);
  width: calc(33.333% - 20px);
  box-sizing: border-box;
  text-align: center;
}

.feature-image {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}

.feature-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.feature-description {
  font-size: 14px;
  color: #666;
}

@media (max-width: 768px) {
  .features-section {
    padding: 0 1em;
  }

  .feature-block {
    width: calc(50% - 20px);
  }

  .content .features-title {
    font-size: 24px;
  }

  .feature-title {
    font-size: 18px;
  }

  .feature-description {
    font-size: 13px;
  }
}

@media (max-width: 480px) {
  .features-section {
    padding: 0;
  }
  .feature-block {
    width: 100%;
  }

  .content .features-title {
    font-size: 20px;
  }

  .feature-title {
    font-size: 16px;
  }

  .feature-description {
    font-size: 12px;
  }
}