.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header-section,
.main-content,
.footer-section {
  width: 100%;
  padding: 20px 0;
}

.header-content,
.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content {
  max-width: 1200px;
  padding: 20px 40px;
  margin: 0 auto;
  box-sizing: border-box;
  width: 100%;
  display: flex; /* Enable flexbox for horizontal layout */
  justify-content: space-between; /* Distribute space between the number blocks */
  align-items: center; /* Center the items vertically */
  flex-wrap: wrap; /* Ensure items wrap to the next line on smaller screens */
}

@media (max-width: 1024px) {
  .content {
    padding: 20px 30px;
  }

  .header-content,
  .footer-content {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 768px) {
  .content {
    padding: 20px 20px;
  }

  .header-content,
  .footer-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .content {
    flex-direction: column;
    align-items: stretch;
  }
}

@media (max-width: 480px) {
  .content {
    padding: 20px 10px;
  }

  .header-content,
  .footer-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .content {
    flex-direction: column;
    align-items: stretch;
  }
}

h1 {
  font-size: 2.1em;
  margin-bottom: 10px;
  font-weight: bold;
}

h2 .subtitle {
  font-size: 1.9em;
  font-weight: bold;
  margin-bottom: 20px;
}

.content-text {
  font-size: 1em;
  margin: 20px 0;
}

.text-center {
  text-align: center;
}

.smaller {
  font-size: 0.8em;
}

.golden {
  color: #ffc425;
}

.blue-color {
  color: #0058a0;
}

/* blue background, with white font color */
.blue-bk {
  background-color: #0058a0;
  color: white;
}
