.footer-content {
  padding-bottom: 10px; /* Space between links and copyright */
}

.footer-links ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links li {
  margin-left: 15px;
}

.footer-links a {
  color: white;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

.footer-copyright {
  text-align: center;
  margin-top: 10px;
  font-size: 0.9em;
  width: 100%;
}

@media (max-width: 1024px) {
  .footer-links ul {
    flex-wrap: wrap;
    justify-content: center;
  }

  .footer-links li {
    margin: 10px;
  }
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-links ul {
    flex-direction: column;
    align-items: center;
  }

  .footer-links li {
    margin: 5px 0;
  }
}

@media (max-width: 480px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-links ul {
    flex-direction: column;
    align-items: center;
  }

  .footer-links li {
    margin: 5px 0;
  }

  .footer-content .logo {
    margin: auto;
  }

  .footer-content .logo a {
    display: inline-block;
  }

  .footer-copyright {
    font-size: 0.8em;
  }
}